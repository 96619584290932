function setTimeoutWithTurbolinks(timeoutFunction, milliseconds) {
  var timeout = setTimeout(timeoutFunction, milliseconds);

  $(document).on('turbolinks:before-cache turbolinks:before-render', function () {
    clearTimeout(timeout);
  });
}

// ------------------------------
// expose some functions globally

docTableHover = function () {
  $(".thumbnail-ct").hover(
    function () {
      $(this).removeClass("-crop");
    },
    // mouse out
    function () {
      $(this).addClass("-crop");
    });
};

refreshIfProcessesRunning = function () {
  var current_url = $(location).attr('href');

  if ($(".processing-open").length > 0) {
    setTimeoutWithTurbolinks(function () {
      $.ajax({
        type: 'GET',
        url: current_url,
        dataType: 'script'
      })

    }, 3000);
  }
}


// prevent duplicate bootstrap-select with browser-navigation
$(document).on('turbolinks:before-cache', function () {
  const $picker = $('.turbolink-selectpicker');
  const picker = $picker.data('selectpicker');
  if (picker) {
    picker.destroy();
    $picker.addClass('turbolink-selectpicker');
  }
});

$(document).on("ready turbolinks:load", function () { 
  $('.turbolink-selectpicker').selectpicker('');

  // $('.thumbnail').ezPlus({
  //   zoomType: 'lens',
  //   scrollZoom: true,
  //   lensFadeIn: 500,
  //   lensSize: 200
  // });

  let status = $('select#status').val();
  if (status) {
    $('input#status').val(status);
  }
});

$(document).on('change', 'select#status', function() {
  let status = $(this).val();

  $('input#status').val(status);
  $('form')[0].submit();
});

$(document).on('click', '.modal-content-control', function(e) {
  previewModalNavigation(e.target);
});

$(document).on('keydown', function(e) {
  if ($('#document-preview-modal').hasClass('show')) {
    let elem = $('button.modal-content-control');
    if ( e.key === 'ArrowRight' ) {
      previewModalNavigation(elem[1]);
    } else if ( e.key === 'ArrowLeft' ) {
      previewModalNavigation(elem[0]);
    }
  }
});

function previewModalNavigation(elem) {
  let current_user = elem.dataset["user"];
  let document = elem.dataset["document"];
  let category;
  let element = "";
  let next_document;

  let documents = $("a[data-target='#document-preview-modal']");

  documents.each(function() {
    if ( $(this)[0].dataset['document'] === document ) {
      element = $(this)[0].dataset['index']
    }
  });
  
  if ( $(elem).hasClass('next') ) {
    next_document = parseInt(element, 10) + 1;
  } else {
    next_document = parseInt(element, 10) - 1;
  }

  if ( documents[next_document] ) {
    document = documents[next_document].dataset['document']
    category = documents[next_document].dataset['category']
  } else if (next_document === -1) {
    document = documents[documents.length - 1].dataset['document']
    category = documents[documents.length - 1].dataset['category']
  } else {
    document = documents[0].dataset['document']
    category = documents[0].dataset['category']
  }

  let action = `/users/${current_user}/categories/${category}/documents/${document}/preview_modal_content`;
  $.ajax({
    type: 'GET',
    url: action,
    dataType: 'script'
  });
}
